import React, { useRef, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { FiArrowLeft } from "react-icons/fi";
import "../../Css/AddStory.css";
import UploadLoader from "../Skeletons/UploadLoader";

const AddCategory = () => {
  const { config } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");

  const clearInputs = () => {
    setTitle("");
  };

  const handleSubmit = async (e) => {
    setIsUploading(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/category/post`,
        { name: title },
        config
      );
      fetchCategories();
      setSuccess("Add Category successfully ");
      clearInputs();
      setTimeout(() => {
        setSuccess("");
      }, 7000);
    } catch (error) {
      setTimeout(() => {
        setError("");
      }, 7000);
      setError(error.response.data.error);
      setIsUploading(false);
    } finally {
      setIsUploading(false);
    }
  };

  const handleEditCategory = (id) => {
    setEditMode(id);
    const categoryToEdit = categories.find((category) => category._id === id);
    setEditedCategoryName(categoryToEdit.name);
  };

  const handleSaveCategory = async () => {
    // Update the category name in the backend
    try {
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/category/${editMode}`,
        { name: editedCategoryName },
        config
      );
      fetchCategories();
      setEditMode(null);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    // Delete the category in the backend
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/category/${id}`,
        config
      );
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/category`
      );
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  console.log(categories);
  useEffect(() => {
    // Fetch category names from an API endpoint or use dummy data
    fetchCategories();
  }, []);
  return (
    <div className="Inclusive-addStory-page ">
      {isUploading && <UploadLoader title="Adding Story Please wait !!" />}
      <Link to={"/"}>
        <FiArrowLeft />
      </Link>
      <form onSubmit={handleSubmit} className="addStory-form">
        {error && <div className="error_msg">{error}</div>}
        {success && (
          <div className="success_msg">
            <span>{success}</span>
            <Link to="/">Go home</Link>
          </div>
        )}

        <input
          type="text"
          required
          id="title"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <button
          type="submit"
          disabled={title ? false : true}
          className={title ? "addStory-btn" : "dis-btn"}
          // onClick={() => POST_Category_Api()}
        >
          Publish
        </button>
      </form>

      {/* Table to display category names */}
      <div className="category-table">
        <h2>Category Names</h2>
        <table className="categories-table">
          <thead>
            <tr>
              <th>Category Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>
                  {editMode === category._id ? (
                    <input
                      type="text"
                      value={editedCategoryName}
                      onChange={(e) => setEditedCategoryName(e.target.value)}
                    />
                  ) : (
                    category.name
                  )}
                </td>
                <td>
                  {editMode === category._id ? (
                    <>
                      <button className="edit-btn" onClick={handleSaveCategory}>
                        Save
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => setEditMode(null)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="edit-btn"
                        onClick={() => handleEditCategory(category._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteCategory(category._id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddCategory;
