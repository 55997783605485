import React, { useRef, useContext, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AuthContext } from "../../Context/AuthContext";
import { AiOutlineUpload } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import "../../Css/AddStory.css";
import UploadLoader from "../Skeletons/UploadLoader";
import useCategories from "../../hooks/useCategories";

const AddStory = () => {
  const { config } = useContext(AuthContext);
  const imageEl = useRef(null);
  const editorEl = useRef(null);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const { categories, isLoading, error: categoriesError } = useCategories();
  
  const clearInputs = () => {
    setTitle("");
    setContent("");
    setImage("");
    editorEl.current.editor.setData("");
    imageEl.current.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("image", image);
    formdata.append("content", content);
    selectedCategories?.forEach((category) => {
      formdata.append("categories", category);
    });

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/story/addstory`,
        formdata,
        config
      );
      setSuccess("Add story successfully ");

      clearInputs();
      setTimeout(() => {
        setSuccess("");
      }, 7000);
    } catch (error) {
      setError(error.response.data.error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    if (e.target.checked) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    }
  };

  return (
    <div className="Inclusive-addStory-page ">
      {isUploading && <UploadLoader title="Adding Story Please wait !!" />}
      <Link to={"/"}>
        <FiArrowLeft />
      </Link>
      <form onSubmit={handleSubmit} className="addStory-form">
        {error && <div className="error_msg">{error}</div>}
        {success && (
          <div className="success_msg">
            <span>{success}</span>
            <Link to="/">Go home</Link>
          </div>
        )}

        <input
          type="text"
          required
          id="title"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <CKEditor
          editor={ClassicEditor}
          onChange={(e, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          ref={editorEl}
        />

        <div id="categoryDiv">
          {isLoading ? (
            <div>Loading...</div>
          ) : categoriesError ? (
            <div>Error: {categoriesError?.message}</div>
          ) : (
            categories?.map((category) => (
              <div key={category._id} className="categoryBtn">
                <input
                  type="checkbox"
                  id={category?._id}
                  value={category?._id}
                  onChange={handleCategoryChange}
                  checked={selectedCategories?.includes(category?._id)}
                />
                <label htmlFor={category?._id}>{category?.name}</label>
              </div>
            ))
          )}
        </div>

        <div className="StoryImageField">
          <AiOutlineUpload />
          <div className="txt">
            {image
              ? image.name
              : " Include a high-quality image in your story to make it more inviting to readers."}
          </div>
          <input
            name="image"
            type="file"
            ref={imageEl}
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        </div>
        <button
          type="submit"
          disabled={image ? false : true}
          className={image ? "addStory-btn" : "dis-btn"}
        >
          Publish
        </button>
      </form>
    </div>
  );
};

export default AddStory;
