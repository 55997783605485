import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import Loader from "../GeneralScreens/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, useParams, Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { AiOutlineUpload } from "react-icons/ai";
import "../../Css/EditStory.css";
import "../../Css/AddStory.css";

import toast from "react-hot-toast";
import UploadLoader from "../Skeletons/UploadLoader";
import useCategories from "../../hooks/useCategories";

const EditStory = () => {
  const { config } = useContext(AuthContext);
  const slug = useParams().slug;
  const imageEl = useRef(null);
  const [loading, setLoading] = useState(true);
  const [story, setStory] = useState({});
  const [image, setImage] = useState("");
  const [previousImage, setPreviousImage] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [previousImageName, setPreviousImageName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { categories, isLoading, error: categoriesError } = useCategories();
  const [selectedCategories, setSelectedCategories] = useState([]);
  console.log(selectedCategories)
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStoryInfo = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/story/editStory/${slug}`,
          config
        );
        console.log(data);
        setStory(data?.data);
        setTitle(data?.data?.title);
        setContent(data?.data?.content);
        setImage(data?.data?.image.data);
        setPreviousImage(data?.data?.image.data);
        setPreviousImageName(data?.data?.image.fileName);
        const selectedCategoryIds = data?.data?.categories.map(
          (category) => category
        );
        setSelectedCategories(selectedCategoryIds);
        setLoading(false);
      } catch (error) {
        // Handle error
      }
    };
    fetchStoryInfo();
  }, [refresh]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("image", image);
    formdata.append("previousImageName", previousImageName);
    selectedCategories.forEach((categoryId) => {
      formdata.append("categories", categoryId); // Append selected category IDs to formdata
    });

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/story/${slug}/edit`,
        formdata,
        config
      );
      setRefresh(!refresh);
      toast.success("Story Edited Successfully!!");
      setSuccess("Story Edited Successfully!!");
    } catch (error) {
      setError(error.response.data.error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    if (e.target.checked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Inclusive-editStory-page ">
          {isUploading && <UploadLoader />}
          <form onSubmit={handleSubmit} className="editStory-form">
            {error && <div className="error_msg">{error}</div>}
            {success && (
              <div className="success_msg">
                <span>{success}</span>
                <Link to="/">Go home</Link>
              </div>
            )}
            <input
              type="text"
              required
              id="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <CKEditor
              editor={ClassicEditor}
              onChange={(e, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
              data={content}
            />
               <div id="categoryDiv">
          {isLoading ? (
            <div>Loading...</div>
          ) : categoriesError ? (
            <div>Error: {categoriesError?.message}</div>
          ) : (
            categories?.map((category) => (
              <div key={category._id} className="categoryBtn">
                <input
                  type="checkbox"
                  id={category?._id}
                  value={category?._id}
                  onChange={handleCategoryChange}
                  checked={selectedCategories?.includes(category?._id)}
                />
                <label htmlFor={category?._id}>{category?.name}</label>
              </div>
            ))
          )}
        </div>
            <div className="currentlyImage">
              <div className="absolute">Currently Image</div>
              <img src={previousImage} alt="storyImage" />
            </div>
            <div className="StoryImageField">
              <AiOutlineUpload />
              <div className="txt">
                {image === previousImage
                  ? "Change the image in your story "
                  : image.name}
              </div>
              <input
                name="image"
                type="file"
                ref={imageEl}
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>
            <button type="submit" className="editStory-btn">
              Edit Story
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditStory;
