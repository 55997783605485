import React from 'react'
import { BounceLoader } from 'react-spinners'

const UploadLoader = ({title="Please Wait!!"}) => {
    return (
        <div style={{ position: "absolute", width: "100%", height: "1250px", backgroundColor: "#4A235AAA", zIndex: 10, top: "1px", left: "1px" }}>
            <div style={{ position: "absolute", width: "100%", height: "70%", zIndex: 10, top: 0, left: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <BounceLoader
                    color="hsla(274, 91%, 52%, 1)"
                    size={100}
                />
                <h1 style={{color:"#fff", opacity: 2, marginLeft:"10px", fontSize: "30px", fontFamily: "inherit"}}>{title}</h1>
            </div>
        </div>)
}

export default UploadLoader
