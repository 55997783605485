import Typewriter from "typewriter-effect";
import PublicHeader from "./PublicHeader";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Main() {
  const Mockdata = [{ 1: 1 }, { 1: 1 }, { 1: 1 }, { 1: 1 }, { 1: 1 }, { 1: 1 }];

  function getRecentBlogs(Mockdata) {
    Mockdata.sort((a, b) => new Date(b.date) - new Date(a.date));
    const recentBlogs = Mockdata.slice(0, 6);
    return recentBlogs;
  }

  function formatDate(dateString) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  function shortenDescription(description, maxLength) {
    if (description.length <= maxLength) {
      return description;
    } else {
      return description.substring(0, maxLength) + "...";
    }
  }

  const [latestStories, setLatestStories] = useState([]);

  console.log(latestStories);
  useEffect(() => {
    async function fetchLatestStories() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/story/getLatestStories`
        );
        if (response.status !== 200) {
          console.error("Failed to fetch latest stories");
          return;
        }
        setLatestStories(response.data.data);
      } catch (error) {
        console.error("Error fetching latest stories:", error);
      }
    }

    fetchLatestStories();
  }, []);

  function shortenDescription(description, maxLength) {
    if (description.length <= maxLength) {
      return description;
    } else {
      return description.substring(0, maxLength) + "...";
    }
  }

  function removeHTMLTags(content) {
    return content.replace(/<\/?[^>]+(>|$)/g, "");
  }

  return (
    <>
      <div className="flex min-h-dvh flex-col bg-white">
        <PublicHeader />
        <main className="flex-1">
          <section
            className="w-full py-[300px]"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1563089145-599997674d42?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            id="home"
          >
            <div className="flex flex-col items-center justify-center gap-4 px-4 text-center md:px-6">
              <div className="space-y-3">
                <h1 className="stroke-red-500 md:text-8xl/lg-tighter text-7xl font-bold tracking-tighter text-white">
                  Narrative Nook Blog
                </h1>
                <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-100">
                  <Typewriter
                    options={{
                      strings: [
                        "Exploring Ideas, Inspiring Minds: Your Journey Starts Here.",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </p>
              </div>
            </div>
          </section>

          <section id="blogs">
            <div className="w-full py-12">
              <div className="space-y-3 text-center">
                <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight ">
                  Latest Articles
                </h2>
                <p className="py-2 text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Read the most recent posts from our team of experts.
                </p>
              </div>
              <div className=" grid gap-6 px-4 md:grid-cols-2 md:px-6 lg:grid-cols-3 xl:gap-8">
                {latestStories.map((val, ind) => (
                  <div
                    key={ind}
                    className="overflow-hidden rounded-lg border-1 border-black"
                  >
                    <a className="overflow-hidden w-full" href="#">
                      <img
                        src={val.image.data}
                        alt="Image"
                        className="aspect-auto w-full max-h-72 object-cover"
                      />
                    </a>
                    <div className="">
                      <div className="p-4">
                        <Link
                          className="font-semibold"
                          to={`/story/${val.slug}`}
                        >
                          {val.title}
                        </Link>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          on {formatDate(val.createdAt)}
                        </p>
                        <p className="mt-2">
                          {shortenDescription(removeHTMLTags(val.content), 220)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Main;
