import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SkeletonStory from "../Skeletons/SkeletonStory";
import CardStory from "../StoryScreens/CardStory";
import NoStories from "../StoryScreens/NoStories";
import Pagination from "./Pagination";
import "../../Css/Home.css";

import { useNavigate } from "react-router-dom";
import CategoryTabs from "./CategoryTabs";

const Home = () => {
  const search = useLocation().search;
  const searchKey = new URLSearchParams(search).get("search");

  const navigate = useNavigate();

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    const fetchStories = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/story/getAllStories`,
          {
            params: { category: selectedCategory, page, search: searchKey },
          }
        );
        setStories(data.data);
        setPages(data.pages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching stories:", error);
        setLoading(false);
      }
    };
    fetchStories();
  }, [selectedCategory, page, searchKey]);
  

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setPage(1); // Reset page number when changing category
  };

  return (
    <div className="Inclusive-home-page">
      {loading ? (
        <div className="skeleton_emp">
          {[...Array(6)].map(() => {
            return (
              // theme dark :> default : light
              <SkeletonStory key={uuidv4()} />
            );
          })}
        </div>
      ) : (
        <div className="story-card-wrapper overall-position">
          <CategoryTabs
            setSelectedCategory={handleCategoryChange}
            selectedCategory={selectedCategory}
          />

          <div className="story-card-wrapper story-card-position ">
            {stories.length !== 0 ? (
              stories.map((story) => {
                  return <CardStory key={uuidv4()} story={story} />;
                })
            ) : (
              <NoStories />
            )}
            <img className="bg-planet-svg" src="planet.svg" alt="planet" />
            <img className="bg-planet2-svg" src="planet2.svg" alt="planet" />
            <img className="bg-planet3-svg" src="planet3.svg" alt="planet" />
          </div>

          <Pagination page={page} pages={pages} changePage={setPage} />
        </div>
      )}
      <br />
    </div>
  );
};

export default Home;
