import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "./SearchForm";
import "../../Css/Header.css";
import { RiPencilFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { TbCategoryPlus } from "react-icons/tb";
import { BsBookmarks } from "react-icons/bs";
import SkeletonElement from "../Skeletons/SkeletonElement";
import { AuthContext } from "../../Context/AuthContext";

const Header = () => {
  const bool = localStorage.getItem("authToken") ? true : false;
  const [auth, setAuth] = useState(bool);
  const { activeUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  // const [loading, setLoading] = useState(true)
  // useEffect(() => {
  //     setAuth(bool)
  //     setTimeout(() => {
  //         setLoading(false)
  //     }, 1600)

  // }, [bool])


  return (
    <header>
      <div className="averager">
        <Link to="/" className="logo">
          <h5>Narrative Nook</h5>
        </Link>
        <SearchForm />
        <div className="header_options">
          {auth ? (
            <div className="auth_options">
              {activeUser.role === "admin" && (
              <Link className="addStory-link flex justify-center items-center" to="/addstory">
              <RiPencilFill /> <p> Add Story </p>
            </Link>
              )}

              {/* <Link to="/readList" className="readList-link">
                <BsBookmarks />
                <span id="readListLength">{activeUser?.readListLength}</span>
              </Link> */}
              <div className="header-profile-wrapper ">
                {loading ? (
                  <SkeletonElement type="minsize-avatar" />
                ) : (
                  <img
                    style={{ objectFit: "contain" }}
                    src={
                      activeUser && activeUser.photo && activeUser.photo.data
                    }
                    alt={activeUser.username}
                  />
                )}

                <div className="sub-profile-wrap ">
                  <Link className="profile-link" to="/profile">
                    <FaUserEdit /> Profile
                  </Link>
                  {activeUser.role === "admin" && (
                    <Link className="profile-link" to="/addcategory">
                      <TbCategoryPlus />
                      Category
                    </Link>
                  )}

                  <button className="logout-btn" onClick={handleLogout}>
                    <BiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="noAuth_options">
              <Link className="login-link" to="/login">
                Login
              </Link>

              <Link className="register-link" to="/register">
                Get Started
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
