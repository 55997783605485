import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

import "../../Css/Header.css";
import { RiPencilFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { TbCategoryPlus } from "react-icons/tb";
import { BsBookmarks } from "react-icons/bs";
import SkeletonElement from "../Skeletons/SkeletonElement";

const PublicHeader = () => {
  const bool = localStorage.getItem("authToken") ? true : false;
  const [auth, setAuth] = useState(bool);
  const { activeUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <header className="fixed top-0 right-0 bg-white w-full">
      <div className="flex items-center h-14 items-center px-4 lg:px-6 gap-4">
        <div className="flex items-center justify-center">
          <Link to="/" className="logo">
            <h5>Narrative Nook</h5>
          </Link>
        </div>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a
            className="text-sm font-medium underline-offset-4 hover:underline"
            href="#home"
          >
            Home
          </a>
          <Link
            className="text-sm font-medium underline-offset-4 hover:underline"
            to="/all-stories"
          >
            All Stories
          </Link>
          <a
            className="text-sm font-medium underline-offset-4 hover:underline"
            href="#blogs"
          >
            Stories
          </a>
          <a
            className="text-sm font-medium underline-offset-4 hover:underline"
            href="#contactus"
          >
            Contact Us
          </a>
        </nav>
        <div className="header_options">
          {auth ? (
            <div className="auth_options">
              {activeUser.role === "admin" && (
                <Link className="addStory-link flex justify-center items-center" to="/addstory">
                  <RiPencilFill /> <p> Add Story </p>
                </Link>
              )}

              {/* <Link to="/readList" className="readList-link">
                <BsBookmarks />
                <span id="readListLength">{activeUser?.readListLength}</span>
              </Link> */}
              <div className="header-profile-wrapper">
                {loading ? (
                  <SkeletonElement type="minsize-avatar" />
                ) : (
                  <img
                    className=" border-2 border-purple-200 rounded-full"
                    style={{ objectFit: "contain" }}
                    src={
                      activeUser && activeUser.photo && activeUser.photo.data
                    }
                    alt={activeUser.username}
                  />
                )}

                <div className="sub-profile-wrap ">
                  <Link className="profile-link" to="/profile">
                    <FaUserEdit /> Profile
                  </Link>
                  {activeUser.role === "admin" && (
                    <Link className="profile-link" to="/addcategory">
                      <TbCategoryPlus />
                      Category
                    </Link>
                  )}

                  <button className="logout-btn" onClick={handleLogout}>
                    <BiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="noAuth_options">
              <Link className="login-link" to="/login">
                Login
              </Link>

              <Link className="register-link" to="/register">
                Get Started
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default PublicHeader;
