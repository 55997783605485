import React, { useEffect, useState } from "react";
import "../../Css/Category.css";
import axios from "axios";

export default function CategoryTabs({
  setSelectedCategory,
  selectedCategory,
}) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/category`
        );
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="container">
      <div className="left-side">
        <p>Blog</p>
      </div>
      <div className="right-side">
        <select value={selectedCategory} onChange={handleCategoryChange}>
          <option value="all">Category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
